.react-datepicker-wrapper input[type="text"] {
  border: none !important;
}

.card-body {
  padding: 10px 25px;
}

h6 {
  font-size: 1.2em;
}

.dark-mode .form-select {
  background: #1a1a3c;
  cursor: pointer;
}

.dark-mode .form-select:hover {
  background: #13132c !important;
}

.dark-mode .input-group-text:hover {
  background-color: #5348c2;
}

.productivity-table-number {
  text-align: right;
  padding: 0 0 0 0em;
}

.productivity-row-table {
  text-align: center;
  font-size: 16px;
}

.table-align-left {
  text-align: left;
}

.table-align-right {
  text-align: right;
}

.productivity-row-table.title {
  text-align: center;
  font-size: 18px;
  font-weight: 800;
}

@media (max-width: 991px) {
  .app-content {
    min-width: 100%;
    padding-top: 3em;
  }
}

.dark-mode .select-default__control,
.dark-mode .select-default__single-value {
  background-color: #1a1a3c !important;
  color: white !important;
}

.dark-mode .select-default__input-container {
  color: white !important;
}

@media (min-width: 991px) {
  .header-brand-img {
    width: 190px;
  }
}

@media (max-width: 991px) {
  .header-brand-img {
    width: 150px;
    margin-bottom: 15px;
  }
}

@media (max-width: 991px) {
  .side-menu {
    padding-top: 50px !important;
  }
}

.fa-spin-header {
  -webkit-animation: fa-spin-header 3000ms infinite linear;
  animation: fa-spin-header 3000ms infinite linear;
}

@-webkit-keyframes fa-spin-header {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin-header {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

fieldset {
  border: 1px solid #2a2a4a;
  background-color: none;
  display: flex;
  padding: 0.2rem !important;
  border-radius: 8px;
}

.dark-mode fieldset {
  border: 1px solid #2a2a4a;
  background-color: #2a2a4a;
  display: flex;
  padding: 0.2rem !important;
  border-radius: 8px;
}

legend {
  float: none;
  width: auto;
  margin-bottom: 0;
}

path.movimiento {
  cursor: pointer;
  transition: all 0.2s ease-out;
  transform-origin: 40% 50%;
}

path.movimiento:hover {
  transition: all 0.25s ease-out;
  fill: rgb(25, 115, 138);
  width: 10;
  transform: scale(1.04);
  pointer-events: all;
}

/* g {
  cursor: pointer;
  transition: all 0.2s ease-out;
  transform-origin: 40% 50%;
} */

g:hover {
  transition: all 0.25s ease-out;
  fill: red;
  width: 10;
  transform: scale(1.04);
  pointer-events: all;
}

.svg-interaction {
  width: 100%;
  height: 100%;
  overflow: visible !important;
}

.hr-productivity {
  background-color: black;
}

.dark-mode .hr-productivity {
  background-color: green;
}

.disabled {
  color: rgb(171, 171, 171);
  cursor: not-allowed;
  pointer-events: none;
}

.dark-mode .disabled,
.dark-mode .disabled a {
  color: rgb(105, 105, 105) !important;
}

.disabled:active,
.disabled:hover {
  color: rgb(171, 171, 171) !important;
}

.table-responsive {
  overflow-x: scroll !important;
}

.rs-btn:focus,
.rs-btn:hover {
  color: black;
}

fieldset legend {
  font-size: 18px;
}

.button-export-heade {
  padding: 2px !important;
}

.popup-title {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.popup-button {
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  background-color: #03a9f4;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 15px;
  color: white;
  font-weight: 800;
  border-color: black;
  outline: black;
}

.popup-image {
  border-radius: 10px;
  border: none;
  outline: none;
}

.modal-mapbox {
  background-color: white;
  width: 40%;
  height: 100%;
  z-index: 4;
  position: absolute;
  right: 0;
  overflow: auto;
  min-width: 400px;
}

.dark-mode .modal-mapbox {
  background-color: #2a2a4a;
  width: 40%;
  height: 100%;
  z-index: 4;
  position: absolute;
  right: 0;
  overflow: auto;
  min-width: 400px;
}

.table-responsive-dev {
  overflow: scroll !important;
}

th {
  font-size: 10px !important;
  word-break: break-all;
}

.table thead th {
  vertical-align: middle !important;
}

.table-button-dev {
  height: 104px !important;
}

.semaphoro {
  display: flex;
  justify-content: space-around;
  height: 15px;
  width: 40%;
  margin-left: 30%;
}

.semaphoro-item-red {
  display: flex;
  background: none;
  align-items: stretch;
  flex: 1;
  margin: 1px;
  border-radius: 50%;
  border: solid black 1px;
  filter: brightness(0.1);
}

.semaphoro-item-yellow {
  background: none;
  flex: 1;
  margin: 1px;
  border-radius: 50%;
  border: solid black 1px;
  filter: brightness(0.1);
}

.semaphoro-item-green {
  background: none;
  flex: 1;
  margin: 1px;
  border-radius: 50%;
  border: solid black 1px;
  filter: brightness(0.1);
}

.semaphoro-item-red.on,
.semaphoro-item-yellow.on,
.semaphoro-item-green.on {
  filter: brightness(1);
}

.semaphoro-item-red.on {
  background-color: rgb(177, 0, 0);
}

.semaphoro-item-yellow.on {
  background-color: yellow;
}

.semaphoro-item-green.on {
  background-color: green;
}

.table-title {
  width: 27%;
}

.table-image-dev {
  width: 15%;
}


.table-autor {
  width: 27%;
}

.table-nv {
  width: 11%;
}

td {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  padding: 0 !important;
  padding-top: 0.32rem !important;
}

.tooltip-table {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  cursor: default;
}

.progress {
  height: 0.4rem;
}

.filter-rainbowCalendar input,
.filter-rainbowCalendar input:focus {
  border: solid 1px rgb(231, 231, 231) !important;
  padding: 18px;
  cursor: pointer;
}

.filter-rainbowCalendar input:hover {
  background-color: rgb(240, 240, 240);
}

.dark-mode .filter-rainbowCalendar input,
.dark-mode .filter-rainbowCalendar input:focus {
  background-color: #1a1a3c;
  color: #9595b5;
  border: none !important;
  cursor: pointer;
  padding: 18px;
}

.dark-mode .filter-rainbowCalendar input:hover {
  background-color: #141430;
}

#dateRainbow_modal {
  background-color: transparent;
}

.dark-mode #dateRainbow_modal section,
.dark-mode #dateRainbow_modal article div,
.dark-mode #dateRainbow_modal td button,
.dark-mode #dateRainbow_modal h3 {
  background-color: #2a2a4a !important;
  color: white !important;
}

.dark-mode #dateRainbow_modal section,
.dark-mode #dateRainbow_modal td span {
  background-color: #1a1a3c !important;
  color: grey !important;
}

.select-default__menu {
  background-color: black;
}

.button-VA {
  background-color: transparent;
  border-radius: 5px;
  font-size: 1.3em;
  padding: 0 20px;
}

.button-VA.active{
  transition: 1s;
  background-color: #6c5ffc;
  color: white;
}

.selector-data {
  display: flex;
  margin: 10px 0;
}

.button-export-heade.disabled {
  color: rgba(214, 214, 214, 0.329) !important;
  pointer-events: all;
}